import React from "react";
import ApplicationSent from "../components/ApplicationSent";
import App from "../components/App";

const ApplicationPage = () => {
  return (
    <App headerChildren={<ApplicationSent />}>
    </App>
  );
};

export default ApplicationPage;
